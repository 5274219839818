<template>
  <div class="vbody">
    <div class="slogan">
      <img
        src="../assets/img/header/slogan.png"
        style="max-width: 100%; width: 100%"
      />
      <div class="cont">
        <div class="name">{{ info.schoolName }}</div>
        <div
          style="font-size: 0.6rem; line-height: 0.8rem; margin-bottom: 0.2rem"
        >
          {{info.slogan}}
        </div>
        <div class="id">
          <span
            style="text-align: right; font-size: 0.7rem; line-height: 0.8rem"
            >授牌编号<br />{{ info.sn }}</span
          >
          <img
            :src="info.code"
            style="width: 1.8rem; height: 1.8rem; margin: 0px 10px"
          />
          <span style="text-align: left; font-size: 0.7rem; line-height: 0.8rem"
            >授牌日期<br />{{
              time
            }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
          >
        </div>
        <b class="fot">全国青少年校园足球工作领导小组办公室</b>
      </div>
    </div>
    <div class="sname">{{ info.schoolName }}</div>
    <div class="list">
      <div class="item">
        <span class="title">学校地址:</span>
        <div class="desc">{{ info.city }}</div>
      </div>
      <div class="item">
        <span class="title">学员总数:</span>
        <div class="desc">{{ info.studentNum }}</div>
      </div>
      <div class="item">
        <span class="title">班级总数:</span>
        <div class="desc">{{ info.gradeNum }}</div>
      </div>
      <div class="item">
        <span class="title">老师总数:</span>
        <div class="desc">{{ info.teacherNmu }}</div>
      </div>
      <div class="item">
        <span class="title">学校性质:</span>
        <div class="desc">{{ info.type == "0" ? "公立" : "私立" }}</div>
      </div>
      <div class="item">
        <span class="title">学校总积分:</span>
        <div class="desc">{{ info.sumCores }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "scanCode",
  components: {},
  data() {
    return {
      info: {},
      time:''
    };
  },
  mounted() {
    this.getBottomInfo();
  },
  methods: {
    getBottomInfo() {
      let data = {
        schoolId: this.$route.query.schoolId,
      };
      this.api.admin.atteSchoolStatisticalInfo(data).then((res) => {
        this.info = res.data;
        // let time = getDateDay(res.data.createtime);
        // this.info.time = time;
        this.info.code = "https://oss.prefootball.cn" + res.data.plaque;
      });
      this.api.admin.getAuthorizationTime(data.schoolId).then((res) => {
        this.time = res.data.verifyDate;
        console.log('===',this.time)
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vbody {
  width: 100%;
  padding: 20px 45px;
  box-sizing: border-box;
}
.slogan {
  width: 100%;
  position: relative;
  .cont {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    line-height: 0.3rem;
    font-size: 0.3rem;
    color: #222;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .name {
      font-size: 0.9rem;
      line-height: 0.9rem;
      font-weight: 700;
      /* color: red; */
      margin-bottom: 0.3rem;
    }
  }
  .id {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0.2rem 0;
    line-height: 0.4rem;
  }
  .fot {
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-bottom: 1rem;
  }
}
.slogan > img {
  width: 100%;
  vertical-align: middle;
  border-style: outset;
}
.sname {
  /* background-color: #cb1b30; */
  background: url("../assets/img/header/snamebg.png") center no-repeat;
  background-size: 100% 2.3rem;
  height: 2.3rem;
  font-size: 0.98rem;
  font-weight: 700;
  color: #fff;
  margin: 1.875rem 0;
  text-align: center;
  line-height: 2.3rem;
}
.list {
  .item {
    display: flex;
    line-height: 1.5rem;
    font-size: 0.8125rem;
    color: #2f2f2f;
    border-bottom: solid 0.0625rem #cb1b30;
    margin-bottom: 1.5rem;
    .title {
      white-space: nowrap;
      background-color: #cb1b30;
      color: #fff;
      padding: 0px 0.625rem;
    }
    .desc {
      padding: 0px 0.625rem;
    }
  }
}
</style>
